// src/router/index.ts (o donde estés configurando el enrutador)
import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../components/Login.vue';
import axios from 'axios';
import { environment } from '../environments/environment'; 
import LoggedInView from '@/components/LoggedInView.vue';
import OrderView from '@/components/OrderView.vue';
import SearchUser from '@/components/SearchUser.vue';
import FooterP from '@/components/FooterP.vue';
import SearchCliente from '@/components/SearchCliente.vue';
import Cookies from 'js-cookie';

const urlBackend = environment.urlPunto_Virtual_Backend;

const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin
  },
  {
    path: '/logged-in',
    name: 'LoggedInView',
    component: LoggedInView,
    meta: { requiresAuth: true }
  },
  {
    path: '/order',
    name: 'Order',
    component: OrderView,
    meta: { requiresAuth: true }
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchUser,
    meta: { requiresAuth: true }
  },
  {
    path: '/menu',
    name: 'SearchCliente',
    component: SearchCliente,
    meta: { requiresAuth: true }
  },
  {
    path: '/footer',
    name: 'FooterP',
    component: FooterP,
    meta: { requiresAuth: true }
  },
  

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token');
  
  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

// llamada al backend de emporium 
const apiBackendEmporium = axios.create({
  baseURL: urlBackend,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor para agregar el token a cada solicitud
apiBackendEmporium.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Interceptor para manejar errores de respuesta
apiBackendEmporium.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Token inválido o expirado
      localStorage.removeItem('jwt');
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export function login(data) {
  return apiBackendEmporium.post('/register/login', data)
    .then(resp => {
      if (resp.data && resp.data.token) {
        // Almacena el token en localStorage
        localStorage.setItem('token', resp.data.token);
      }
      return resp;
    })
    .catch(error => {
      //console.error('API call error:', error);
      throw error;
    });
}


export function searchProducts(data) {
  const userPWAData = Cookies.get('userPWA') ? JSON.parse(Cookies.get('userPWA')) : null;

  const codPrecio = userPWAData.codprecio;
  const nit = userPWAData.nit;
  const strUsuario = userPWAData.userName;
  return apiBackendEmporium.get(`/products/name/${data}/${codPrecio}/${nit}/0/${strUsuario}/0`);
      
}

export function searchPClientes() {
  return apiBackendEmporium.get(`/order/consultClientMtProcli`);
}

export function searchCC(requestData) {
  
  return apiBackendEmporium.post(`/centrocosto/upsert`, requestData);
}

export function createPedido(data) {
  const resp = apiBackendEmporium.post(`/pedido/insertPedido`, data);
  return resp;
}

export function aprobPedido(data) {
  const resp = apiBackendEmporium.post(`/pedido/pedidoAprob`, data);
  return resp;
}



export function selectP(data) {
  
  return apiBackendEmporium.post(`/centrocosto/upsert`, data);
}



export default router;
