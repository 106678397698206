<template>
  <SearchCliente :nombrCC="nombrCC" :clienNom="clienNom" :centrosPe="centrosPe" />
  <div class="app-container">
    <div class="order-container">
      <h2>Resumen del Pedido</h2>

      <!-- Mensaje de no hay productos -->
      <div v-if="order.length === 0" class="no-products">
        No hay productos en el pedido.
      </div>

      <!-- Lista de productos -->
      <div class="products">
        <div v-for="product in order" :key="product.id" class="product-card">
          <!-- Información del producto -->

          <div class="product-info">
            <div class="product-details">

              <img :src="getProductImage(product?.images)" alt="Imagen del producto" class="popup-image"
                @error="(e) => e.target.src = 'https://ep1.elpunto.com.co/puntocadenaimages/000000000.jpg'" />

              <h3 class="infonNa">{{ product.name }}</h3>
              <!-- <p class="info info1"><Strong class="titulo">Categoria: {{ fuuu }}</Strong></p>
              <p class="info info1">

                <Strong class="titulo">Estado: </Strong>{{product.availibilityCount > 0 ? "Disponible" : "No Disponible"}}
              </p>  -->

              <!-- <p class="info info2"><Strong class="titulo">SKU: </Strong> {{ product.id }}</p> -->
              <table class="tabli">
                <tr class="table">
                  <td><strong class="titulo">IVA:</strong> {{ product.iva }}</td>
                  <td><strong class="titulo">ANTES DEL IVA:</strong> {{ formatcantidad(product.price) }}</td>

                </tr>
                <tr class="table">
                  <td><strong class="titulo">Cantidad:</strong> {{ formatcantidad(product.cantidad) }}</td>
                  <td><strong class="titulo">Disp:</strong> {{ product.availibilityCount }}</td>
                </tr>
              </table>

              <p class="product-price">
                ${{
                  formatcantidad(
                    (product.price * product.iva) / 100 + product.price
                  )
                }}
              </p>
            </div>

            <div class="product-actions">
              <p class="info">Cantidad:</p>
              <button @click="decreasecantidad(product)" class="cantidad-button">
                -
              </button>
              <input type="number" v-model.number="product.cantidad" min="1" :max="product.availibilityCount"
                class="cantidad-input" />
              <button @click="increasecantidad(product)" class="cantidad-button">
                +
              </button>
              <button class="remove-from-list-button" @click="removeFromOrder(product.id)">
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Resumen del precio total -->
      <div class="resumen">
        <div class="order-summary">
          <p class="total-price">Total items: {{ order.length }}</p>
        </div>

        <div class="order-summary">
          <p class="total-price">Subtotal: ${{ formatcantidad(calculateTotalPrice()) }}</p>
        </div>
        <div class="order-summary">
          <p class="total-price">Iva: ${{ formatcantidad(calculateTotalIva()) }}</p>
        </div>
        <div class="order-summary">
          <p class="total-price">Total: ${{ formatcantidad(calculTotalConIva()) }}</p>
        </div>

        <router-link to="/logged-in" class="back-to-products-link">
          Volver a Productos
        </router-link>
        <button class="confirm-order-button" @click="confirmOrder">
          Confirmar Pedido
        </button>







        <div v-if="isLoading" class="loading-overlay">
          <div class="loading-popup">
            <atom-spinner :animation-duration="1000" :size="60" color="#ff1d5e" />
            <p>{{ loadingMessage }}</p>
          </div>
        </div>



        <!-- Popup para la imagen del producto -->
        <div v-if="showPopup" class="popup-overlay" @click="closeImagePopup">
          <div class="popup-content" style="width: 65%" @click.stop>
            <button class="close-popup-button" @click="closeImagePopup">
              <font-awesome-icon :icon="['fas', 'xmark']" />
            </button>
            <img :src="getProductImage(selectedProduct?.fotoEcom)" alt="Imagen del producto" class="popup-image" />
            <p>{{ selectedProduct.name }}</p>

            <div class="cantidad-container">
              <button @click="decreasecantidad(selectedProduct)" class="cantidad-button">
                -
              </button>
              <input type="number" v-model.number="selectedProduct.cantidad" min="1"
                :max="selectedProduct.availibilityCount" placeholder="Cantidad" class="cantidad-input" />
              <button @click="increasecantidad(selectedProduct)" class="cantidad-button">
                +
              </button>
            </div>
            <button class="update-cantidad-button" @click="updatecantidad(selectedProduct.id)">
              Aceptar
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import SearchCliente from '../components/SearchCliente.vue'
import { createPedido, aprobPedido } from "@/router/router";
import { ref } from 'vue';
import Cookies from 'js-cookie';
import { AtomSpinner } from 'epic-spinners'
import Swal from 'sweetalert2';

export default {
  components: {
    SearchCliente,
    AtomSpinner
  },
  name: 'OrderView',
  setup() {
    const order = ref(JSON.parse(localStorage.getItem('order')) || []);
    const selectedProduct = ref(null);
    const showPopup = ref(false);
    let isLoading = ref(false);
    let loadingMessage = ref('');

    const getProductImage = (fotoEcom) => {
      const imageUrl = fotoEcom?.split(',')[0];
      return imageUrl
        ? `https://ep1.elpunto.com.co/puntocadenaimages/${imageUrl}`
        : 'https://ep1.elpunto.com.co/puntocadenaimages/000000000.jpg';
    };

    const openImagePopup = (product) => {
      selectedProduct.value = { ...product }; // Clonar el producto para edición
      showPopup.value = true;
    };

    const closeImagePopup = () => {
      showPopup.value = false;
    };

    const updatecantidad = (productId) => {
      order.value = order.value.map(item =>
        item.id === productId ? { ...item, cantidad: selectedProduct.value.cantidad } : item
      );
      localStorage.setItem('order', JSON.stringify(order.value));
      closeImagePopup();
    };

    const removeFromOrder = (productId) => {
      order.value = order.value.filter(item => item.id !== productId);
      localStorage.setItem('order', JSON.stringify(order.value));
    };



    const confirmOrder = async () => {

      isLoading.value = true;
      loadingMessage.value = "Enviando información del pedido...";

      try {
        const orderData = JSON.parse(localStorage.getItem('order'));
        const storedUser = JSON.parse(Cookies.get('userPWA'));
        const idPedido = {
          nit: storedUser.nit,
          nota: '',
          nombreClienteGeneral: '',
          nitOpcional: '',
          centroCosto: storedUser.nit,
          vendedor: storedUser.codVen,
          userName: storedUser.userName,
          accion: '',
          logInterno: storedUser.logInterno,
          puntoEnvio: storedUser.codigoPE,
          ordenCompra: '',
          tipoOperacion: 'aproPwa',
          cuponCode: '',
          valorCupon: 0
        }


        loadingMessage.value = "Creando pedido...";
        const resp = await createPedido(idPedido);

        if (resp.data) {
          loadingMessage.value = "Enviando detalles del pedido...";
          const data = {
            nit: storedUser.nit,
            idPedido: resp.data.toString(),
            usuario: storedUser.userName,
            perfil: JSON.stringify(storedUser),
            logInterno: storedUser.logInterno,
            destino: 5,
            fechaPausa: '',
            nota: '',
            estado: 'SENVI',
            pedidos: orderData,
            puntoEnvio: storedUser.codigoPE,
            ordenCompra: '',
            tipoOperacion: 'aproPwa',
            cuponCode: '',
            valorCupon: 0
          }
          try {
            const respDetalle = await aprobPedido(data);

            if (respDetalle) {
              Swal.fire({
                title: 'Pedido Enviado',
                text: respDetalle.data.message,
                icon: 'success',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                customClass: {
                  confirmButton: 'swal-confirm-button', // Puedes usar esta clase para personalizar el botón
                },
              });
              order.value = [];
              localStorage.removeItem('order');
              this.$router.push('/search')
            }
          } catch (error) {
            Swal.fire({
              title: `Pedido ${resp.data} Enviado`,
              text: error.response.data.message,
              icon: 'success',
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              customClass: {
                confirmButton: 'swal-confirm-button', // Puedes usar esta clase para personalizar el botón
              },
            });
            order.value = [];
            localStorage.removeItem('order');
            this.$router.push('/search')

          }
        }

      } catch (error) {
        console.error("Error al procesar el pedido:", error);
        loadingMessage.value = "Hubo un error al procesar el pedido.";
      } finally {
        // Finaliza el loading
        isLoading.value = false;
        loadingMessage.value = '';  // Limpiar el mensaje de estado
      }
    };


    const formatcantidad = (cantidad) => {
      return Number(cantidad).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    };

    const decreasecantidad = (product) => {
      if (product.cantidad > 1) {
        product.cantidad -= 1;
      }
    };

    const increasecantidad = (product) => {
      if (product.cantidad < product.availibilityCount) {
        product.cantidad += 1;
      }
    };
    const calculateTotalPrice = () => {
      return order.value.reduce((total, product) => total + product.price * product.cantidad, 0);
    };

    const calculateTotalIva = () => {
      const totalIva = order.value.reduce((total, product) => {
        const ivaProducto = ((product.price * product.iva) / 100) * product.cantidad;
        return total + ivaProducto;
      }, 0);
      return totalIva;
    }

    const calculTotalConIva = () => {
      return calculateTotalPrice() + calculateTotalIva();
    }

    return {
      order,
      selectedProduct,
      showPopup,
      getProductImage,
      openImagePopup,
      closeImagePopup,
      updatecantidad,
      removeFromOrder,
      calculateTotalPrice,
      confirmOrder,
      formatcantidad,
      decreasecantidad,
      increasecantidad,
      loadingMessage,
      isLoading,
      calculTotalConIva,
      calculateTotalIva

    };
  },
};
</script>
<style scoped>
.order-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
}



.product-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  max-width: 400px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-info {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  text-align: justify;
}

.product-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

.product-price {
  font-size: 1.1rem;
  font-weight: bold;
  color: #233a7a;
  margin-bottom: 0.5rem;
}

.product-cantidad {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.product-actions {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
}

.show-image-button {
  padding: 0.3rem 0.5rem;
  background-color: #bdc3c7;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 0.5rem;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.show-image-button:hover {
  background-color: #95a5a6;
}

.remove-from-list-button {
  padding: 0.5rem 1rem;
  background-color: #233a7a;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-from-list-button:hover {
  background-color: #c0392b;
}

.order-summary {

  text-align: center;
}

.total-price {
  font-size: 1.25rem;
  font-weight: bold;
  color: #233a7a;
}

.confirm-order-button {
  padding: 0.75rem 1.5rem;
  background-color: #233a7a;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.confirm-order-button:hover {
  background-color: #1a2a55;
}

.back-to-products-link {
  margin-top: 1rem;
  color: #233a7a;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.back-to-products-link:hover {
  color: #2980b9;
}

.no-products {
  color: #666;
  font-size: 1.1rem;
  margin-top: 2rem;
  text-align: center;
}

/* Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: #fff;
  padding: 2rem;
  border-radius: 12px;
  position: relative;
  max-width: 500px;
  width: 65%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-image {
  max-width: 86%;
  height: auto;
  border-radius: 8px;
}

.close-popup-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
}

.close-popup-button:hover {
  background: #c0392b;
}

.popup-details {
  margin-top: 1rem;
}

.cantidad-input {
  width: 6rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 0 0.5rem;
  box-sizing: border-box;
}

.update-cantidad-button {
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.update-cantidad-button:hover {
  background-color: #2980b9;
}

.cantidad-button {
  background-color: transparent;
  color: #b3bcc7;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  width: 2.5rem;

  transition: background-color 0.3s ease;

  font-size: 42px;
}

.cantidad-button:hover {
  background-color: #a3a3a3;
}

.tabli {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table td {
  width: 50%; 
  padding: 10px; 
  /* border: 1px solid #ddd; */
  text-align: left; 
  vertical-align: middle; 
  box-sizing: border-box;
}

.tabli td {
  font-size: 16px;
}






.info {
  color: #b3bcc7;
}

.infonNa {
  color: black;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 76vh;
}

.products {
  display: flex;
  height: 57vh;
  overflow: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

header,
footer {
  position: sticky;
  z-index: 10;
}

header {
  top: 0;
}

footer {
  bottom: 0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.loading-popup p {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  /* Texto en color oscuro */
}

.resumen {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.titulo {
  color: black;
}



@media (max-width: 700px) {
  .resumen {
    display: block;

    font-size: 14px;
  }

  .total-price {

    font-size: 14px;
  }

  .order-summary {
    display: table-cell;
  }

  .order-container {
    width: 100%;
  }

  .app-container {
    width: 100%;
  }

  .infonNa {
    font-size: 16px;
  }

  .info {
    font-size: 16px;
  }

  .popup-image {
    max-width: 59%;
  }

  .product-details {
    text-align: center;
  }

  .info1 {
    display: contents;
  }

  .info2 {
    display: contents;
  }

  .info3 {
    display: contents;
  }

  .info4 {
    display: contents;
  }
}
</style>
